import React, { useEffect, useState } from 'react';
import classes from './PropertiesComp.module.scss'
import Checkbox from "../../../UI/Checkbox/Checkbox";
import { setProperties } from "../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import Error from "../../../Error/Error";

const PropertiesComp = ( props ) => {
    const checkboxes = [
        {
            label: `COTTAGE`,
            name: 'cottage'
        },
        {
            label: `RENTAL PROPERTY(IES)`,
            name: 'rental'
        },
        {
            label: `NO`,
            name: 'no'
        },
    ];

    const initState = {
        checkedItems: new Map(),
    };

    const [checked, setChecked] = useState(initState);

    const [error, setError] = useState(false);

    const changeValHandler = (e) => {

        const item = e.target.name;
        const isChecked = e.target.checked;

        if (e.target.name === 'no') {
            checked.checkedItems.set('cottage', false)
            checked.checkedItems.set('rental', false)
        }else {
            checked.checkedItems.set('no', false)
        }

        setChecked({ checkedItems: checked.checkedItems.set(item, isChecked) });
        props.setProperties(checked.checkedItems)
    };

    useEffect(() => {
        let count = 0;
        if (props.cottage) {
            checked.checkedItems.set('cottage', true);
            count++
        }

        if (props.rental) {
            checked.checkedItems.set('rental', true);
            count++
        }

        if (!props.propertiesExist) {
            checked.checkedItems.set('no', true);
            count++
        }

        setChecked({ checkedItems: checked.checkedItems });

        if(count === 0 ) {
            setError(true)
        }else {
            setError(false)
        }

    },[checked.checkedItems, props]);

    return (
        <div className={classes.Properties}>
            {
                error &&
                <Error
                    message='Selection required'
                />
            }
            {
                checkboxes.map((input, index) => {
                    return (
                        <Checkbox
                            key={index}
                            onChange={changeValHandler}
                            label={input.label}
                            name={input.name}
                            checked={ checked.checkedItems.get(input.name) }
                        />
                    )
                })
            }
        </div>
    )
};

function mapStateToProps(state) {
    return {
        cottage: state.savingsAndInvestments.properties.isCottage,
        rental: state.savingsAndInvestments.properties.isRentalProperties,
        propertiesExist: state.savingsAndInvestments.properties.exist,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setProperties : checkedItems => dispatch(setProperties(checkedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesComp);