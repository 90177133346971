import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import Navigation from "../../../../components/Navigation/Navigation";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import PropertiesComp from "../../../../components/ContentRow/Step3/Properties/PropertiesComp";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const Properties = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR SAVINGS & INVESTMENTS',
        sectionTitle: 'Do you own a cottage or rental properties?',
    };

    const title = "Retirement - Do you own a cottage or rental properties?";

    const next = retirementCalcLinks.homeEquity;

    useEffect(() => {
        props.thirdStepCompleted(false);
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(13/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                <InnerLayoutStepContent type={'family'}>
                    <PropertiesComp/>
                </InnerLayoutStepContent>

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Properties);